body {
	margin: 0;
	font-family: "Mundial", sans-serif;
	font-weight: 200;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #f2f2f2;
	background-color: #000102;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
